<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">新增商品</span>
      <div class="row">
        <div class="col-md-12">
          <el-row>
            <el-col :span="8">
              <div class="form-group">
                <label><b>分类</b></label><br/>
                <el-cascader :props="{label:'NAME',value:'ID',children:'CHILDREN'}" @change="getStock"
                             v-model="cat.val" :options="cat.ls" placeholder="请选择分类"/>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>物品</b></label><br/>
                <el-select v-model="model.ST_ID" placeholder="请选择物品" @change="stockChange" filterable>
                  <el-option
                      v-for="item in ls_stock"
                      :key="item.ID"
                      :label="item.NAME"
                      :value="item.ID">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>库存量</b></label>
                <div>
                  <el-input v-model="stock.BALANCE" readonly style="width: 60%;">
                    <template slot="prepend" ><i class="el-icon-coin"/></template>
                  </el-input>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <div class="form-group">
                <label><b>申请数量</b></label>
                <div>
                  <el-input-number v-model="model.CNT" :step="1"></el-input-number>
                </div>
              </div>
            </el-col>
            <el-col :span="8" v-if="act_cat==100">
              <div class="form-group">
                <label><b>* 金额</b>(元)</label>
                <div>
                  <el-input-number v-model="model.AMT" :step="1"  :min="0"></el-input-number>
                </div>
              </div>
            </el-col>
            <el-col :span="8" v-if="act_cat==200">
              <div class="form-group">
                <label><b>归属班级</b></label><br />
                <el-select v-model="model.CLASS_ID">
                  <el-option
                      v-for="item in ls_class"
                      :key="item.ID"
                      :label="item.NAME"
                      :value="item.ID">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>备注</b></label>
                <el-input type="text" v-model="model.DESC" />
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-image v-if="stock.PIC_NAME" style="width: 320px;" :src="stock.PIC_URL" :preview-src-list="[stock.PIC_URL]" />
            </el-col>
          </el-row>
        </div>
      </div>
      <div slot="footer">
        <el-button icon="el-icon-close" @click="sta.show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data(){
    return{
      act_cat:100,
      sta:{
        show:false,
        loading:false
      },
      model: {},
      stock:{},
      cat:{
        ls:[],
        val:[]
      },
      ls_stock:[],
      ls_class:[]
    }
  },
  methods:{
    init(cat) {
      this.sta={show:true,loading:false}
      this.act_cat=cat;
      this.model = {};
      this.stock = {}
      if (this.cat.ls.length==0) this.getCat();
      if (this.act_cat==200 && this.ls_class.length == 0) this.getClass();
    },
    getClass(){
      let self=this;
      this.whale.remote.getCollection({
        url: "/api/School/ORG/ClassApi/GetList",
        data: {},
        completed: function (its) {
          self.ls_class = its;
        }
      })
    },
    getCat(){
      let self = this;
      this.whale.remote.getResult({
        url: "/api/School/MAT/MCatApi/GetTree",
        completed: function (its) {
          self.cat.ls=its.DATA;
        }
      })
    },
    getStock(v){
      let n=v.length;
      if (n>0) this.model.CAT_ID=v[n-1];
      let self=this;
      this.whale.remote.getCollection({
        url:"/api/School/MAT/StockApi/GetList",
        data: {CAT_ID:this.model.CAT_ID},
        completed:function (its,n){
          self.model.ST_ID="";
          self.ls_stock=its;
          if (n==1) self.stockSel(its[0]);
        }
      })
    },
    stockChange(v){
      let self=this;
      this.ls_stock.forEach(it=>{
        if (it.ID==v) self.stockSel(it)
      });
    },
    stockSel(it){
      this.stock=it;
      this.model.ST_ID=it.ID
      this.model.ST_NAME=it.NAME;
      this.model.ST_UNIT=it.FUNIT;
      this.model.ST_PRICE=it.PRICE;
      this.model.CAT_NAME=it.CAT_NAME;
      this.model.CAT_ID=it.CAT_ID;
    },
    submit(){
      let self = this;
      if (this.model.ST_ID==undefined){
        this.whale.toast.err("请选择物品")
        return
      }
      if (this.model.CNT==undefined){
        this.whale.toast.err("请输入数量")
        return
      }
      if (this.act_cat==100){
        if (this.model.AMT==undefined || this.model.AMT<=0){
          this.whale.toast.err("请输入金额,且大于0")
          return
        }
      }else{
        this.model.AMT=this.model.ST_PRICE*this.model.CNT;
        if (this.model.CLASS_ID>0){
          this.ls_class.map(x=>{
            if(x.ID==this.model.CLASS_ID){
              this.model.CLASS_NAME=x.CLASS_NAME;
            }
          })
        }
      }
      self.sta.show=false;
      self.$emit("on-saved",this.model);
    }
  }
}
</script>
